<template>
	<div class="home">


		<b-jumbotron>
			<b-container style="height:100%;">
				<img alt="AI" src="../assets/ai.png" height="250">
				<br><br>


				<p style="text-align:left;">
					<b>Artificial intelligence (AI)</b> is one of several modern approaches to achieve human-equivalent machine intelligence [[i]]. It is described as a field of study focused on creating intelligent entities, with numerous applications in various domains including security, commerce, and intelligent transportation system. <b>Machine learning (ML)</b> is a tool to help create and implement artificial intelligence systems, and leans heavily onto statistical methods to accomplish its goals. While an artificial intelligence system may perceive its environment with sensors and take actions with actuators, machine learning enables the system to learn from data collected from these sensors.

					<br><br>
					Despite the fact that there are numerous perceived benefits for developing human-equivalent machine intelligence, such as fostering the rapid development of human technological advancement, there are also a number of <b>public concerns</b> about the technology ranging from economic instability to apocalyptic.
					
					<br><br>
					To address some of these challenges, Dr. El-Khatib launched a project supported by the <b>Office of the Privacy Commissioner of Canada’s (OPC)</b> early in 2020. The project will explore known privacy risks associated with artificial intelligence and machine learning, identify popular use cases and create a venue to discuss their privacy associated risks. The project has a number of deliverables, including:
					<ul>
						<li>Interim discussions of the project in the Machine Learning course (INFR 3700U), the Artificial Intelligence (CSCI 4610U), and the Global A.I. Ethics course (CSCI 6720G) at Ontario Tech University.</li>
						<li>Paper(s) submitted to peer-reviewed conference and journals detailing research findings.</li>
						<li>Hold a number of online seminars acknowledging the contribution of the OPC. The seminars will host experts in the field to talk about privacy with AI and ML systems.</li>
						<li>Training of highly qualified personnel in the technical and information-related disciplines.</li>
						<li>Posters and presentations summarizing the findings of the project at various AI, ML and privacy related conferences</li>
						<li>Production of consumer-friendly material regarding privacy, AI, and ML.</li>
						<li>Use of social media to disseminate research as well as involving community in order to solicit concerns, experiences with AI and ML.</li>
						<li>Creation of a project website using multi-media visual aids to showcase project research and outcomes.</li>
					</ul>
					At this point, there are two publications that came out of this project.
					<ul>
						<li>AI and Privacy Cheat Sheet: Companies that are looking to implement AI technologies need to take important and bold steps to avoid mishaps especially when handling personal data. Some of these steps are described in this cheat sheet. <a href="https://s3.amazonaws.com/privacy-and-ai.com/docs/AI and Privacy Cheat Sheet.pdf">Available here.</a></li>
						<li>A journal paper titled "Privacy and Artificial Intelligence" published in the Journal of IEEE Transaction on Artificial Intelligence. <a href="https://ieeexplore.ieee.org/document/9450036?source=authoralert">Available here.</a></li>
					</ul>
				</p>
				
			</b-container>
		</b-jumbotron>
	</div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: 'Home'
}
</script>


